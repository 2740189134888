import {FC, ReactNode, Fragment, useState} from 'react';
import {Popover, Transition} from '@headlessui/react'
import Link from "next/link";
import Image from "next/image";
import {
  BookmarkAltIcon,
  BriefcaseIcon,
  ChartBarIcon,
  CheckCircleIcon,
  CursorClickIcon,
  DesktopComputerIcon,
  GlobeAltIcon,
  InformationCircleIcon,
  MenuIcon,
  NewspaperIcon,
  OfficeBuildingIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  ViewGridIcon,
  XIcon,
} from '@heroicons/react/outline'
import {ChevronDownIcon} from '@heroicons/react/solid'
import SVGIcon from "@/components/SVG";
import classnames from 'classnames';


interface Navigation {
  type: 'link'
  label: string
  destination: string
}

interface primaryNavigation {
  type: 'link' | 'parentLink'
  label: string
  destination: string
  childLinks: Navigation[]
}

const primaryNavigation = [
  {
    type: 'parentLink',
    label: 'About Us',
    destination: '#',
    childLinks: [
      {
        type: 'link',
        label: 'Our Philosophy',
        destination: '/our-philosophy'
      },
      {
        type: 'link',
        label: 'Our Brisbane Dentists',
        destination: '/our-brisbane-dentists'
      }
    ],
  },
  {
    type: 'parentLink',
    label: 'Dental Services',
    destination: '#',
    childLinks: [
      {
        type: 'link',
        label: 'Our Philosophy',
        destination: '/our-philosophy'
      },
      {
        type: 'link',
        label: 'Our Brisbane Dentists',
        destination: '/our-brisbane-dentists'
      }
    ],
  },
  {
    type: 'parentLink',
    label: 'Health Funds',
    destination: '#',
    childLinks: [
      {
        type: 'link',
        label: 'Our Philosophy',
        destination: '/our-philosophy'
      },
      {
        type: 'link',
        label: 'Our Brisbane Dentists',
        destination: '/our-brisbane-dentists'
      }
    ],
  },
  {
    type: 'link',
    label: 'Our Blog',
    destination: '/our-blog'
  },
  {
    type: 'link',
    label: 'FAQs',
    destination: '/faqs'
  },
  {
    type: 'link',
    label: 'Contact Us',
    destination: '/contact-us'
  },
]

const hardCBDPrimaryNav = [
  'About Us',
  'Dental Services',
  'Health Funds',
  'Our Blog',
  'FAQs',
  'Contact Us'
]

interface NavBarProps {
  children?: ReactNode | ReactNode[]
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const healthfundsa = [
  {
    name: 'Hicaps',
    href: '/health-funds/hicaps-dentist',
    image: "hicaps-menu.png",
  },
  {
    name: 'SACM',
    href: '/health-funds/sacm-dentist',
    image: "sacm-menu.png"
  },
  {
    name: 'Smile.com.au',
    href: '/health-funds/smile-dentist',
    image: "smile-com-au-menu.png"
  },
  {
    name: 'Travel Insurance',
    href: '/health-funds/travel-dentist',
    image: "travel-insurance-menu.png"
  },
]
// /health-funds/australian-unity-dentist
const associatedHealthFunds = [
  {
    name: 'Australian Unity',
    href: '/health-funds/australian-unity-dentist',
    image: "australian-unity-menu.png",
  },
  {
    name: 'Budget Direct',
    href: '/health-funds/budget-direct-dentist',
    image: "budget-direct-menu.png"
  },
  {
    name: 'CBHS Health Fund',
    href: '/health-funds/cbhs-dentist',
    image: "cbhs-health-fund-menu.png"
  },
  {
    name: 'Defence Health',
    href: '/health-funds/defence-health-dentist',
    image: "defence-health-fund-menu.png"
  },
  {
    name: 'frank health insurance',
    href: '/health-funds/frank-health-dentist',
    image: "frank-health-insurance-menu.png"
  },
  {
    name: 'GMHBA',
    href: '/health-funds/gmhba-dentist',
    image: "gmhba-health-insurance-menu.png"
  },
  {
    name: 'health.com.au',
    href: '/health-funds/health-dentist',
    image: "healthcomau-insurance-menu.png"
  },
  {
    name: 'HCF',
    href: '/health-funds/hcf-dentist',
    image: "hcf-insurance-menu.png"
  },
  {
    name: 'nib',
    href: '/health-funds/nib-dentist',
    image: "nib-health-insurance-menu.png"
  },
  {
    name: 'Peoplecare',
    href: '/health-funds/peoplecare-dentist',
    image: "peoplecare-insurance-menu.png"
  },
  {
    name: 'Health Care Insurance',
    href: '/health-funds/hci-dentist',
    image: "healthcare-hci-menu.png"
  },
  {
    name: 'myOwn health insurance',
    href: '/health-funds/myown-dentist',
    image: "myown-health-insurance-menu.png"
  },
]
const governmentDentalSchemes = [
  {
    name: 'Department of Veterans\' Affairs',
    href: '/health-funds/dva-dentist',
    image: "australian-government-department-of-veteran-affairs-menu.png",
  },
  {
    name: 'Child Dental Benefits Schedule',
    href: '/health-funds/cdbs-dentist',
    image: "medicare-menu.png"
  }
]

const callsToAction = [
  {name: 'Watch Demo', href: '#', icon: "heroiconsMapMarker"},
  {name: 'View All Products', href: '#', icon: CheckCircleIcon},
  {name: 'Contact Sales', href: '#', icon: PhoneIcon},
]
const currentDeals = [
  {
    id: 1,
    name: 'New Patient Deals',
    href: '/contact',
    preview: 'Comprehensive dental check and clean - $190 or No Gap',
    imageUrl:
      '/static/images/tiles/sherloch-toothy-general-dentist.png.webp',
  },
  {
    id: 2,
    name: 'Regular Patient Specials',
    href: '/contact',
    preview: 'You can get your teeth whitening for just $660, contact us for more information.',
    imageUrl:
      '/static/images/tiles/sunbaking-toothy-teeth-whitening.png.webp',
  },
]
const singleLinks = [
  {
    name: 'our_blog',
    label: 'Our Blog',
    destination: '/our-blog'
  },
  {
    name: 'faqs',
    label: 'FAQs',
    destination: '/faqs'
  },
  {
    name: 'contact-us',
    label: 'Contact Us',
    destination: '/contact'
  }
]
const aboutGroupLinks = [
  {
    name: 'about_us',
    label: 'About Us',
    group: [
      {
        name: 'our_philosophy',
        label: 'Our Philosophy',
        destination: '/about/our-philosophy',
        icon: InformationCircleIcon
      },
      {
        name: 'our_brisbane_dentists',
        label: 'Our Brisbane Dentists',
        destination: '/about/brisbane-dentist-team',
        icon: InformationCircleIcon
      },
      {
        name: 'dr_vincent_wan',
        label: 'Dr. Vincent Wan',
        destination: '/about/dr-vincent-wan',
        icon: InformationCircleIcon
      },
      {
        name: 'dr_allen_wan',
        label: 'Dr. Allen Wan',
        destination: '/about/dr-allen-wan',
        icon: InformationCircleIcon
      },
      // {
      //   name: 'dr_sima_dezfuli',
      //   label: 'Dr. Sima Dezfuli',
      //   destination: '/about/dr-sima-dezfuli',
      //   icon: InformationCircleIcon
      // },
    ]
  }
]
const servicesGroupLinks = [
  {
    name: 'dental_services',
    label: 'Dental Services',
    groups: [
      {
        name: 'preventive_dentistry',
        label: 'Preventive Dentistry',
        icon: 'preventive-dentistry-mm.png.webp',
        group: [
          {
            name: 'dental_checkup',
            label: 'Dental Checkup',
            destination: '/dental-services/dental-checkup'
          },
          {
            name: 'teeth_cleaning',
            label: 'Teeth Cleaning',
            destination: '/dental-services/teeth-cleaning'
          },
          {
            name: 'tooth_mousse',
            label: 'Tooth Mousse',
            destination: '/dental-services/tooth-mousse'
          },
          {
            name: 'sedation_anxiety',
            label: 'Sedation & Anxiety',
            destination: '/dental-services/sedation-anxiety'
          }
        ],
      },
      {
        name: 'cosmetic_dentistry',
        label: 'Cosmetic Dentistry',
        icon: 'cosmetic-dentistry-mm.png.webp',
        group: [
          {
            name: 'aligner_orthodontics',
            label: 'Aligner Orthodontics',
            destination: '/dental-services/aligner-orthodontics'
          },
          {
            name: 'dental_veneers_brisbane',
            label: 'Dental Veneers',
            destination: '/dental-services/dental-veneers-brisbane'
          },
          {
            name: 'invisible_braces',
            label: 'Invisible Braces',
            destination: '/dental-services/invisible-braces'
          },
          {
            name: 'teeth_whitening_brisbane',
            label: 'Teeth Whitening Brisbane',
            destination: '/dental-services/teeth-whitening-brisbane'
          },
          {
            name: 'quick_straight_teeth',
            label: 'Quick Straight Teeth',
            destination: '/dental-services/quick-straight-teeth'
          }
        ],
      },
      {
        name: 'restorative_dentistry',
        label: 'Restorative Dentistry',
        icon: 'restorative-dentistry-mm.png.webp',
        group: [
          {
            name: 'dental_bridges_brisbane',
            label: 'Dental Bridges Brisbane',
            destination: '/dental-services/dental-bridges-brisbane'
          },
          {
            name: 'dental_crowns_brisbane',
            label: 'Dental Crowns',
            destination: '/dental-services/dental-crowns-brisbane'
          },
          {
            name: 'dental_fillings_brisbane',
            label: 'Dental Fillings Brisbane',
            destination: '/dental-services/dental-fillings-brisbane'
          },
          {
            name: 'dental_implants_brisbane',
            label: 'Dental Implants',
            destination: '/dental-services/dental-implants-brisbane'
          },
          {
            name: 'dentures',
            label: 'Dentures',
            destination: '/dental-services/dentures'
          }
        ],
      },
      {
        name: 'guards_splints',
        label: 'Guards & Splints',
        icon: 'mouth-guards-mm.png.webp',
        group: [
          {
            name: 'sports_mouthguards',
            label: 'Sports Mouthguards',
            destination: '/dental-services/sports-mouthguards'
          },
          {
            name: 'teeth_clenching_grinding',
            label: 'Teeth Clenching & Grinding',
            destination: '/dental-services/teeth-clenching-grinding'
          },
          {
            name: 'snoring_sleep_apnoea',
            label: 'Snoring & Sleep Apnoea',
            destination: '/dental-services/snoring-sleep-apnoea'
          },
          {
            name: 'tooth_wear',
            label: 'Tooth Wear',
            destination: '/dental-services/tooth-wear'
          }
        ],
      },
      {
        name: 'pain_relief',
        label: 'Pain Relief',
        icon: 'pain-relief-mm.png.webp',
        group: [
          {
            name: 'emergency_dentist',
            label: 'Emergency Dentist',
            destination: '/dental-services/emergency-dentist'
          },
          {
            name: 'general_dental_extractions',
            label: 'General Dental Extractions',
            destination: '/dental-services/general-dental-extractions'
          },
          {
            name: 'root_canal_treatment',
            label: 'Root Canal Treatment',
            destination: '/dental-services/root-canal-treatment'
          },
          {
            name: 'wisdom_teeth_extraction',
            label: 'Wisdom Teeth & Other Extractions',
            destination: '/dental-services/wisdom-teeth-other-extractions'
          }
        ],
      },
    ],
  }
]

const NavBar: FC<NavBarProps> = ({children}) => {
  // const {isSidebarOpen, closeSidebar} = useUI()
  const mobileNavigation = [
    {
      name: 'About',
      href: '#',
      children: [
        {
          name: 'Our Philosophy',
          href: '/about/our-philosophy'
        },
        {
          name: 'Our Brisbane Dentists',
          href: '/about/our-brisbane-dentists'
        },
        {
          name: 'Dr Vincent Wan',
          href: '/about/dr-vincent-wan'
        },
        {
          name: 'Dr Allen Wan',
          href: '/about/dr-allen-wan'
        },
        // {
        //   name: 'Dr Sima Dezfuli',
        //   href: '/about/dr-sima-dezfuli'
        // },
      ]
    },
    {
      name: 'Dental Services',
      href: '#',
      children: [
        {
          name: 'Dental Checkup',
          href: '/dental-services/dental-checkup'
        },
        {
          name: 'Teeth Cleaning',
          href: '/dental-services/teeth-cleaning'
        },
        {
          name: 'Tooth Mousse',
          href: '/dental-services/tooth-mousse'
        },
        {
          name: 'Sedation & Anxiety',
          href: '/dental-services/sedation-anxiety'
        },
        {
          name: 'Aligner Orthodontics',
          href: '/dental-services/aligner-orthodontics'
        },
        {
          name: 'Dental Veneers',
          href: '/dental-services/dental-venners'
        },
        {
          name: 'Invisible Braces',
          href: '/dental-services/invisible-braces'
        },
        {
          name: 'Teeth Whitening Brisbane',
          href: '/dental-services/teeth-whitening-brisbane'
        },
        {
          name: 'Quick Straight Teeth',
          href: '/dental-services/quick-straight-teeth'
        },
        {
          name: 'Dental Bridges Brisbane',
          href: '/dental-services/dental-bridges-brisbane'
        },
        {
          name: 'Dental Crowns',
          href: '/dental-services/dental-crowns-brisbane'
        },
        {
          name: 'Dental Fillings',
          href: '/dental-services/dental-fillings-brisbane'
        },
        {
          name: 'Dental Implants',
          href: '/dental-services/dental-implants-brisbane'
        },
        {
          name: 'Dentures',
          href: '/dental-services/dentures'
        },
        {
          name: 'Sports Mouthguards',
          href: '/dental-services/sports-mouthguards'
        },
        {
          name: 'Teeth Clenching & Grinding',
          href: '/dental-services/teeth-clenching-grinding'
        },
        {
          name: 'Snoring & Sleep Apnoea',
          href: '/dental-services/snoring-sleep-apnoea'
        },
        {
          name: 'Tooth Wear',
          href: '/dental-services/tooth-wear'
        },
        {
          name: 'Emergency Dentist',
          href: '/dental-services/emergency-dentist'
        },
        {
          name: 'General Dental Extractions',
          href: '/dental-services/general-dental-extractions'
        },
        {
          name: 'Root Canal Treatment',
          href: '/dental-services/root-canal-treatment'
        },
        {
          name: 'Wisdom Teeth Extractions',
          href: '/dental-services/wisdom-teeth-other-extractions'
        },

      ]
    },
    {
      name: 'Health Funds',
      href: '#',
      children: [
        {
          name: 'Hicaps Dentist',
          href: '/health-funds/hicaps-dentist'
        },
        {
          name: 'SACM Dentist',
          href: '/health-funds/sacm-dentist'
        },
        {
          name: 'Smile Dentist',
          href: '/health-funds/smile-dentist'
        },
        {
          name: 'Travel Dentist',
          href: '/health-funds/travel-dentist'
        },
        {
          name: 'Australian Unity Dentist',
          href: '/health-funds/australian-unity-dentist'
        },
        {
          name: 'Budget Direct Dentist',
          href: '/health-funds/budget-direct-dentist'
        },
        {
          name: 'CBHS Dentist',
          href: '/health-funds/cbhs-dentist'
        },
        {
          name: 'Defence Health Dentist',
          href: '/health-funds/defence-health-dentist'
        },
        {
          name: 'Frank Health Dentist',
          href: '/health-funds/frank-health-dentist'
        },
        {
          name: 'GMHBA Dentist',
          href: '/health-funds/gmhba-dentist'
        },
        {
          name: 'HCF Dentist',
          href: '/health-funds/health-dentist'
        },
        {
          name: 'HCF Dentist',
          href: '/health-funds/hcf-dentist'
        },
        {
          name: 'NIB Dentist',
          href: '/health-funds/nib-dentist'
        },
        {
          name: 'Peoplecare Dentist',
          href: '/health-funds/peoplecare-dentist'
        },
        {
          name: 'HCI Dentist',
          href: '/health-funds/hci-dentist'
        },
        {
          name: 'MyOwn Dentist',
          href: '/health-funds/myown-dentist'
        },
        {
          name: 'DVA Dentist',
          href: '/health-funds/dva-dentist'
        },
        {
          name: 'CDBS Dentist',
          href: '/health-funds/cdbs-dentist'
        },
      ]
    },
    {
      name: 'Blog',
      href: '/our-blog'
    },
    {
      name: 'FAQs',
      href: '/faqs'
    },
    {
      name: 'Contact Us',
      href: '/contact'
    },
  ]
  const close = (open: boolean) => {
    open = false
    return open
  }

  const [activeMobileMenu, setActiveMobileMenu] = useState('')

  return (
    <Popover className="relative bg-white">

      {({open, close}) => (
        <>
          <div
            className="container mx-auto flex justify-between items-center px-4 py-2 sm:px-6 md:justify-start md:space-x-10"
          >

            {/* Logo Component */}
            <div
              // className="flex justify-start lg:w-0 lg:flex-1"
            >
              <Link
                href="/"
              >
                <a className="flex">
                  <span className="sr-only">Central Brisbane Dental</span>
                  <Image
                    layout="intrinsic"
                    height="116.5px" // 182px
                    width="280px" //420px
                    className="h-8 w-auto sm:h-10"
                    src="/static/images/logo/logo-cbd-2022.png"
                  />
                </a>
              </Link>
            </div>

            {/* Mobile Menu */}
            <div className="-mr-2 -my-2 md:hidden">
              <Popover.Button
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-800 focus:outline-none">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true"/>
              </Popover.Button>
            </div>

            <Transition
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden"
              >
                <div className="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
                  <div className="flex items-center justify-between px-5 pt-4">
                    <div className="-mr-2">
                      <Popover.Button
                        className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Close main menu</span>
                        <SVGIcon
                          name={"heroiconsXMarker"}
                          stroke="#dc2626"
                          viewBox="0 0 24 24"
                          className={"h-6 w-6 text-[#ff6600]"}
                        />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="space-y-1 px-2 pt-2 pb-3">
                    {mobileNavigation.map((item) => {
                      if (item.href !== '#') {
                        return (
                          <Link
                            href={item.href}
                          >
                            <a
                              key={item.name}
                              className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 no-underline"
                            >
                              {item.name}
                            </a>
                          </Link>
                        )
                      } else {
                        return (
                          <span
                            key={item.name}
                            className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 no-underline"
                            onClick={() => setActiveMobileMenu(item.name)}
                          >
                           {item.name}
                            <SVGIcon
                              name={"heroiconsCaretRight"}
                              fill="#000"
                              stroke="#fff"
                              viewBox="0 0 24 24"
                              className={"h-7 w-7 inline"}
                            />
                           <div
                             className={classnames(
                               item.name === activeMobileMenu ? "block" : "hidden"
                             )}
                           >
                             {item.children?.map((subItem, index) => {
                               return (
                                 <Link
                                   href={subItem.href}
                                 >
                                   <a
                                     key={subItem.name}
                                     className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 no-underline"
                                   >
                                     {subItem.name}
                                   </a>
                                 </Link>
                               )
                             })}
                           </div>
                         </span>
                        )
                      }
                    })}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>

            {/* Navigation Component */}
            <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">

              <Popover.Group as="nav" className="flex space-x-10">
                {/* About Us */}
                <>
                  {aboutGroupLinks.map((linkGroup, index) => {

                    return (
                      <Popover>

                        {({open}) => (
                          <>
                            <Popover.Button
                              className={classNames(
                                open ? 'text-gray-500' : 'text-black',
                                'group bg-white rounded-md inline-flex items-center text-base font-medium',
                                "hover:text-gray-900 focus:outline-none focus:ring-1 focus:ring-offset-8 focus:ring-[#ff6600]"
                              )}
                            >
                              <span>{linkGroup.label}</span>

                              <ChevronDownIcon
                                className={classNames(
                                  open ? 'text-gray-500' : 'text-black',
                                  'ml-2 h-5 w-5 group-hover:text-gray-500'
                                )}
                                aria-hidden="true"
                              />

                            </Popover.Button>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 -translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 -translate-y-1"
                            >

                              <Popover.Panel
                                className="hidden md:block absolute z-10 top-full inset-x-0 transform shadow-lg">
                                <div className="absolute inset-0 flex">

                                  <div className="bg-white w-1/2"/>
                                  {/*<div className="bg-gray-50 w-1/2"/>*/}
                                  <div className="bg-[#131313] w-1/2"/>

                                </div>
                                <div className="relative max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2">
                                  <nav
                                    className="grid gap-y-10 px-4 py-8 bg-white sm:grid-cols-2 sm:gap-x-8 sm:py-12 sm:px-6 lg:px-8 xl:pr-12">
                                    <>
                                      {linkGroup.group ? (
                                        <div>

                                          <h3
                                            className="text-md font-medium tracking-wide text-black uppercase">
                                            About Us
                                          </h3>

                                          <ul role="list" className="mt-5 space-y-6">

                                            <>
                                              {linkGroup.group.map((group, index) => {
                                                return (
                                                  <li key={group.label} className="flow-root">
                                                    <Link
                                                      href={group.destination}
                                                    >
                                                      <a
                                                        className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-black hover:bg-gray-50 hover:text-[#ff6600] no-underline"
                                                      >
                                                        <group.icon className="flex-shrink-0 h-6 w-6 text-[#400068]"
                                                                    aria-hidden="true"/>
                                                        <span className="ml-4">{group.label}</span>
                                                      </a>
                                                    </Link>
                                                  </li>
                                                )
                                              })}
                                            </>

                                          </ul>

                                        </div>
                                      ) : null}
                                    </>
                                  </nav>
                                  <div className="px-4 py-8 sm:py-12 sm:px-6 lg:px-8 xl:pl-12">
                                    <div>
                                      <h3 className="text-lg font-bold tracking-wide text-[#FF6600] uppercase">
                                        View current deals
                                      </h3>
                                      <ul role="list" className="mt-6 space-y-6">
                                        {currentDeals.map((post) => (
                                          <li key={post.id} className="flow-root">
                                            <Link
                                              href={post.href}
                                            >
                                              <a
                                                className="group -m-3 p-3 flex rounded-lg hover:bg-gray-100 no-underline">
                                                <div className="hidden sm:block flex-shrink-0">
                                                  <img className="w-32 h-20 object-cover rounded-md" src={post.imageUrl}
                                                       alt=""/>
                                                </div>
                                                <div className="w-0 flex-1 sm:ml-8">
                                                  <h4
                                                    className="text-base font-medium text-white group-hover:text-[#17AAAB] truncate">
                                                    {post.name}
                                                  </h4>
                                                  <p className="mt-1 text-sm text-gray-200 group-hover:text-black">
                                                    {post.preview}
                                                  </p>
                                                </div>
                                              </a>
                                            </Link>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                    <div className="mt-6 text-sm font-medium">
                                      <Link
                                        href="our-blog"
                                      >
                                        <a className="text-[#17AAAB] hover:text-[#FF6600] no-underline">
                                          {' '}
                                          View all dental tips & articles <span aria-hidden="true">&rarr;</span>
                                        </a>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </Popover.Panel>
                            </Transition>

                          </>
                        )}

                      </Popover>
                    )
                  })}
                </>
                {/* Dental Services */}
                <>
                  {servicesGroupLinks.map((linkGroup, index) => {

                    return (
                      <Popover>
                        {({open}) => (
                          <>
                            {/* Menu Item */}
                            <Popover.Button
                              className={classNames(
                                open ? 'text-gray-500' : 'text-black',
                                'group bg-white rounded-md inline-flex items-center text-base font-medium',
                                "hover:text-gray-900 focus:outline-none focus:ring-1 focus:ring-offset-8 focus:ring-[#ff6600]"
                              )}
                            >
                              <span>{linkGroup.label}</span>

                              <ChevronDownIcon
                                className={classNames(
                                  open ? 'text-gray-500' : 'text-black',
                                  'ml-2 h-5 w-5 group-hover:text-gray-500'
                                )}
                                aria-hidden="true"
                              />

                            </Popover.Button>

                            {/* Menu Item Flyout */}
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 -translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 -translate-y-1"
                            >

                              <Popover.Panel
                                className="hidden md:block absolute z-10 top-full inset-x-0 transform shadow-lg bg-gray-200">

                                <div
                                  className="max-w-7xl mx-auto grid gap-y-6 px-4 py-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-5 lg:px-8 lg:py-10">

                                  <>
                                    {linkGroup.groups.map((groupsLinkGroup, index) => {

                                      return (
                                        <Link
                                          href={"#"}
                                        >
                                          <a
                                            key={`groups-link-group-${index}`}
                                            // href={item.name}
                                            className="-m-3 p-3 flex flex-col justify-between items-center rounded-lg no-underline"
                                          >
                                            <div className="flex md:h-full lg:flex-col">
                                              <div className="flex-shrink-0 text-center">
                                                <span
                                                  className="inline-flex items-center justify-center h-10 w-10 rounded-md text-white sm:h-32 sm:w-32">
                                                  <img src={`/static/images/menu/${groupsLinkGroup.icon}`}/>
                                                </span>
                                              </div>
                                              <div
                                                className="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                                                <div>
                                                  <p className="text-base font-medium text-gray-900 my-2">
                                                    {/*{item.name}*/}
                                                    {groupsLinkGroup.label}
                                                  </p>
                                                  <ul>
                                                    {groupsLinkGroup.group.map((groupsLinkGroupLinkGroup, index) => {
                                                      return (
                                                        <li
                                                          key={`group-links-link-group-${index}`}
                                                          className={`mt-1`}
                                                        >
                                                          <Link
                                                            href={groupsLinkGroupLinkGroup.destination}
                                                          >
                                                            <a
                                                              className="text-gray-800 p-1 rounded-md hover:bg-[#131313] hover:text-[#ff6600] no-underline">
                                                              {groupsLinkGroupLinkGroup.label}
                                                            </a>
                                                          </Link>
                                                        </li>
                                                      )
                                                    })}
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          </a>
                                        </Link>
                                      )
                                    })}
                                  </>

                                  <>
                                    {/*{solutions.map((item) => (*/}
                                    {/*  <a*/}
                                    {/*    key={item.name}*/}
                                    {/*    href={item.href}*/}
                                    {/*    className="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50"*/}
                                    {/*  >*/}
                                    {/*    <div className="flex md:h-full lg:flex-col">*/}
                                    {/*      <div className="flex-shrink-0">*/}
                                    {/*<span*/}
                                    {/*  className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">*/}
                                    {/*  <item.icon className="h-6 w-6" aria-hidden="true"/>*/}
                                    {/*</span>*/}
                                    {/*      </div>*/}
                                    {/*      <div*/}
                                    {/*        className="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">*/}
                                    {/*        <div>*/}
                                    {/*          <p className="text-base font-medium text-gray-900">{item.name}</p>*/}
                                    {/*          <p className="mt-1 text-sm text-gray-500">{item.description}</p>*/}
                                    {/*        </div>*/}
                                    {/*        <p className="mt-2 text-sm font-medium text-indigo-600 lg:mt-4">*/}
                                    {/*          Learn more <span aria-hidden="true">&rarr;</span>*/}
                                    {/*        </p>*/}
                                    {/*      </div>*/}
                                    {/*    </div>*/}
                                    {/*  </a>*/}
                                    {/*))}*/}
                                  </>

                                </div>

                                <>
                                  {/*<div className="bg-gray-50">*/}
                                  {/*  <div*/}
                                  {/*    className="max-w-7xl mx-auto space-y-6 px-4 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-6 lg:px-8">*/}
                                  {/*    {callsToAction.map((item) => (*/}
                                  {/*      <div key={item.name} className="flow-root">*/}
                                  {/*        <a*/}
                                  {/*          href={item.href}*/}
                                  {/*          className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"*/}
                                  {/*        >*/}
                                  {/*          <SVGIcon*/}
                                  {/*            name={item.icon}*/}
                                  {/*            fill="#000"*/}
                                  {/*            stroke="#000"*/}
                                  {/*            viewBox="0 0 24 24"*/}
                                  {/*            className={"h-6 w-6"}*/}
                                  {/*          />*/}
                                  {/*          <span className="ml-3">{item.name}</span>*/}
                                  {/*        </a>*/}
                                  {/*      </div>*/}
                                  {/*    ))}*/}
                                  {/*  </div>*/}
                                  {/*</div>*/}
                                </>

                              </Popover.Panel>

                            </Transition>
                          </>
                        )}
                      </Popover>
                    )
                  })}
                </>
                {/* Health Funds */}
                <Popover>
                  {({open}) => (
                    <>
                      {/* Menu Item */}
                      <Popover.Button
                        className={classNames(
                          open ? 'text-gray-500' : 'text-black',
                          'group bg-white rounded-md inline-flex items-center text-base font-medium',
                          "hover:text-gray-900 focus:outline-none focus:ring-1 focus:ring-offset-8 focus:ring-[#ff6600]"
                        )}
                      >
                        <span>Health Funds</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? 'text-gray-600' : 'text-gray-400',
                            'ml-2 h-5 w-5 group-hover:text-gray-500'
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      {/* Menu Item Flyout */}
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 -translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 -translate-y-1"
                      >
                        <Popover.Panel
                          className="hidden md:block absolute z-10 top-full inset-x-0 transform shadow-lg bg-gray-200">
                          <div
                            className="mx-auto max-w-7xl grid gap-y-6 px-4 py-4 sm:grid-cols-2 sm:gap-8 lg:grid-cols-4">
                            {healthfundsa.map((item) => (
                              <Link
                                href={item.href}
                              >
                                <a
                                  key={item.name}
                                  className="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50 no-underline"
                                >
                                  <div className="flex md:h-full lg:flex-col">
                                    <div className="flex-shrink-0">
                                      <Image
                                        layout="intrinsic"
                                        height="92px" // 182px
                                        width="280px" //420px
                                        className="h-9 sm:h-10"
                                        src={`/static/images/healthfunds/${item.image}`}
                                        alt="Smile.com.au health fund logo icon transparent"
                                      />
                                    </div>
                                    <div
                                      className="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0">
                                      <div>
                                        <p className="text-base font-medium text-gray-900 text-center">{item.name}</p>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </Link>
                            ))}
                          </div>
                          <div
                            className="max-w-7xl mx-auto grid px-2 py-2">
                            <span className="text-black font-bold text-sm">Associated Health Funds</span>
                          </div>
                          <div
                            className="mx-auto max-w-7xl grid gap-y-6 px-4 py-4 sm:grid-cols-2 sm:gap-8 lg:grid-cols-6">
                            {associatedHealthFunds.map((item) => (
                              <Link
                                href={item.href}
                              >
                                <a
                                  key={item.name}
                                  className="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50 no-underline"
                                >
                                  <div className="flex md:h-full lg:flex-col">
                                    <div className="flex-shrink-0">
                                      <Image
                                        layout="intrinsic"
                                        height="92px" // 182px
                                        width="280px" //420px
                                        className="h-9 sm:h-10"
                                        src={`/static/images/healthfunds/${item.image}`}
                                        alt="Smile.com.au health fund logo icon transparent"
                                      />
                                    </div>
                                    <div
                                      className="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0">
                                      <div>
                                        <p className="text-base font-medium text-gray-900 text-center">{item.name}</p>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </Link>
                            ))}
                          </div>
                          <div
                            className="max-w-7xl mx-auto grid px-2 py-2">
                            <span className="text-black font-bold text-sm">Government Dental Schemes</span>
                          </div>
                          <div
                            className="mx-auto max-w-7xl grid gap-y-6 px-4 py-4 sm:grid-cols-2 sm:gap-8 lg:grid-cols-4">
                            {governmentDentalSchemes.map((item) => (
                              <Link
                                href={item.href}
                              >
                                <a
                                  key={item.name}
                                  className="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50 no-underline"
                                >
                                  <div className="flex md:h-full lg:flex-col">
                                    <div className="flex-shrink-0">
                                      <Image
                                        layout="intrinsic"
                                        height="92px" // 182px
                                        width="280px" //420px
                                        className="h-9 sm:h-10"
                                        src={`/static/images/healthfunds/${item.image}`}
                                        alt="Smile.com.au health fund logo icon transparent"
                                      />
                                    </div>
                                    <div
                                      className="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0">
                                      <div>
                                        <p className="text-base font-medium text-gray-900 text-center">{item.name}</p>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </Link>
                            ))}
                          </div>
                          <>
                            {/*<div className="bg-gray-50">*/}
                            {/*  <div*/}
                            {/*    className="max-w-7xl mx-auto space-y-6 px-4 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-6 lg:px-8">*/}
                            {/*    {callsToAction.map((item) => (*/}
                            {/*      <div key={item.name} className="flow-root">*/}
                            {/*        <a*/}
                            {/*          href={item.href}*/}
                            {/*          className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"*/}
                            {/*        >*/}
                            {/*          <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true"/>*/}
                            {/*          <span className="ml-3">{item.name}</span>*/}
                            {/*        </a>*/}
                            {/*      </div>*/}
                            {/*    ))}*/}
                            {/*  </div>*/}
                            {/*</div>*/}
                          </>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>

                {/* Standard Links */}
                {/* Out Blog */}
                {singleLinks.map((link, index) => {
                  return (
                    <Link
                      href={link.destination}
                    >
                      <a
                        key={`single-link-${index}`}
                        className={classNames(
                          "text-black hover:text-gray-500",
                          "text-base font-medium no-underline"
                        )}
                      >
                        {link.label}
                      </a>
                    </Link>
                  )
                })}

              </Popover.Group>

            </div>

          </div>
        </>
      )}

    </Popover>
  )
}

export default NavBar
